import { isBefore } from "date-fns";

import {
  chooseClosestAvailableDate,
  openProductDatepicker,
} from "../chooseClosestAvailableDate";

(function ($, chooseClosestAvailableDate, openProductDatepicker, dateService) {
  "use strict";

  var hour,
    minute,
    seconds,
    cutoff_time,
    todayDate,
    holidayDate,
    todayComparator,
    holidayComparator,
    days,
    isBeforeHoliday,
    timeLeft,
    datesResponse,
    dataLayer = (window.dataLayer = window.dataLayer || []);

  // The product page (gambit) has two timers, hide the
  // container along with the timer on the mobile view
  var mobileCountdownContainer = $(".product__timer.mobile");

  function init() {
    todayDate = $("#today_date").val();
    holidayDate = $("#holiday_date").val();
    todayComparator = new Date(
      todayDate.substring(0, 4),
      parseInt(todayDate.substring(5, 7), 10) - 1,
      todayDate.substring(8, 10)
    );
    holidayComparator = new Date(
      holidayDate.substring(0, 4),
      parseInt(holidayDate.substring(5, 7), 10) - 1,
      holidayDate.substring(8, 10)
    );
    isBeforeHoliday = todayComparator.getTime() < holidayComparator.getTime();

    deliveryCutoffCountdown(todayDate, isBeforeHoliday);
  }

  // This function setups the countdown timer.
  // AJAX call to get list of closed days
  function deliveryCutoffCountdown(todayDate, beforeHoliday) {
    if (beforeHoliday) {
      // Special countdown
      createHolidayDeliveryCountdown(holidayDate, beforeHoliday);
    }

    createDeliveryPickupCountdowns(todayDate, beforeHoliday);
  }

  function createDeliveryPickupCountdowns(getDate, beforeHoliday) {
    var datesClosed = $.ajax({
      url: "/storefronts/datesClosed?tempThemeDate=" + getDate,
      dataType: "json",
    });

    datesClosed.done(function (response) {
      datesResponse = response;

      if (datesResponse.pickup_today) {
        $("#pickup_cutoff_desktop").text(datesResponse.pickup_cutoff);
        $("#pickup_cutoff_mobile").text(datesResponse.pickup_cutoff);

        if (!datesResponse.delivery_today) {
          $(".no-pickup").hide();
          $(".need-it-today").removeClass("hide");
          $("#delivery_dates_container").attr(
            "style",
            "padding-bottom: 30px !important"
          );
        }
      }

      attachClickToTab("#pickupTab");

      if (!datesResponse.delivery_disabled) {
        attachClickToTab("#deliveryTab");
        $(".product__datepicker-pickup-cutoff").addClass("hide");
      } else {
        $("#deliveryTab").append(
          '<span class="tooltip">There are currently no dates available for delivery.</span>'
        );
      }

      handleDeliveryToday(datesResponse.delivery_today, beforeHoliday);

      handlePickupToday(datesResponse.pickup_today);

      handlePickupDisabled(datesResponse.pickup_disabled);

      grayOutDates(datesResponse);

      // Check to the delivery today field, disable today's date if delivery today has passed
      chooseClosestAvailableDate("delivery", datesResponse);
      // Do not pick closest available date, default to delivery always (LV-6518)
      // Check to the pickup today field, disable today's date if pickup today has passed
      if (!datesResponse.pickup_disabled) {
        // Check to the pickup today field, disable today's date if pickup today has passed
        chooseClosestAvailableDate('pickup', datesResponse);
        
        // Ensure the pickup tab is properly initialized
        $("#pickupTab").removeClass("hide");
        $(".product__datepicker-tabs-container").removeClass("hide");
      }

      // Only open the datepicker for the active tab, when no other dates are chosen
      openProductDatepicker(
        $("#o_type").val() === "localdelivery" ? "delivery" : "pickup"
      );

      // Do not pick closest available date, default to delivery always (LV-6518)
      // if (
      //   (
      //     !datesResponse.pickup_disabled &&
      //     dateService.isBefore(new Date($('#hidden_pickup_date').val()), new Date($('#hidden_delivery_date').val()))
      //   ) ||
      //   (
      //     !datesResponse.pickup_disabled &&
      //     datesResponse.delivery_disabled
      //   )
      // ) {

      //   $('#pickupTab').click();
      // }

      if (!datesResponse.pickup_today) {
        $(".product__datepicker-pickup-cutoff").addClass("hide");
      }

      // Set up timer
      setUpTimer(
        "delivery",
        false,
        beforeHoliday,
        datesResponse.delivery_cutoff_difference,
        datesResponse.delivery_today
      );

      if (!datesResponse.pickup_disabled) {
        // Set up timer
        setUpTimer(
          "pickup",
          false,
          beforeHoliday,
          datesResponse.pickup_cutoff_difference,
          datesResponse.pickup_today
        );
      }
    });
  }

  function grayOutDates(datesResponse) {
    // Disable date buttons if the store is not accepting delivery/pickup on that day
    $("#pickup_datepicker .dateLink, #delivery_datepicker .dateLink").each(
      function () {
        if ($(this).hasClass("grayedOut")) {
          return;
        }

        var dateCheck = $(this).data("datecheck");
        var thisDate = $(this).data("thisdate");
        var orderType = $(this).data("ordertype");

        var closedList =
          orderType === "delivery"
            ? datesResponse.no_delivery_dates
            : datesResponse.no_pickup_dates;
        var dayClosed = closedList.indexOf(thisDate) !== -1;
        var beforeToday = dateService.isBefore(
          new Date(thisDate),
          new Date(dateCheck)
        );

        if (beforeToday || dayClosed) {
          $(this).addClass("grayedOut");
          $(this).attr("aria-disabled", "true");
        }
      }
    );

    if (datesResponse.pickup_disabled) {
      $("#pickupTab, #deliveryTab, #pickup_dates_container").hide();
      $("#delivery_dates_container").removeClass(
        "product__datepicker-days-container"
      );
    }
  }

  function attachClickToTab(selector) {
    $(selector).removeClass("disabled");

    $(selector).click(function () {
      var type = $(this).data("ordertype");
      var otherType = type === "delivery" ? "pickup" : "delivery";

      // Get tab and tab contents, toggle active class
      $(
        "#" + otherType + "_dates_container, #" + otherType + "Tab"
      ).removeClass("active");

      $("#" + type + "_dates_container, #" + type + "Tab").addClass("active");

      $(".product__datepicker-free-delivery").toggleClass("hide");

      if (type === "delivery") {
        $("#pickup_dates_container").css("padding-bottom", "0px");
        $("#pickupCutoffCopyDesktop").addClass("hide");
        $(".orderTimer").removeClass("hide");

        if (datesResponse.pickup_today && !datesResponse.delivery_today) {
          $(".no-pickup").hide();
          $(".need-it-today").removeClass("hide");
          $("#delivery_dates_container").attr(
            "style",
            "padding-bottom: 30px !important"
          );
        } else {
          $(".no-pickup").removeClass("hide");
          $(".need-it-today").addClass("hide");
          if ($(".no-free-delivery").length) {
            $("#delivery_dates_container").attr(
              "style",
              "padding-bottom: 17px !important"
            );
          }
        }
      } else {
        $("#pickupCutoffCopyDesktop").removeClass("hide");

        if (!datesResponse.delivery_today && !datesResponse.pickup_today) {
          $("#pickup_dates_container").css("padding-bottom", "17px");
        } else {
          $("#pickup_dates_container").css("padding-bottom", "30px");
        }

        $(".product__datepicker-free-delivery").addClass("hide");
        $(".orderTimer").addClass("hide");
      }

      $("#o_type").val(type === "delivery" ? "localdelivery" : "pickup");
      // get the delivery_dates_container and if there is class active on it, loop through the nested children and
      // add tabindex="0" to a tag that has class dateLink and active class on it and button element
      if ($("#delivery_dates_container").hasClass("active")) {
        $("#delivery_dates_container")
          .find("a.dateLink:not(.grayedOut), button.ui-datepicker-trigger")
          .attr("tabindex", "0");

        // Set tabindex="-1" for grayedOut links
        $("#delivery_dates_container")
          .find("a.dateLink.grayedOut")
          .attr("tabindex", "-1");
        $("#pickup_dates_container")
          .find("a.dateLink, button.ui-datepicker-trigger")
          .attr("tabindex", "-1");
      } else if ($("#pickup_dates_container").hasClass("active")) {
        $("#pickup_dates_container")
          .find("a.dateLink:not(.grayedOut), button.ui-datepicker-trigger")
          .attr("tabindex", "0");

        // Set tabindex="-1" for grayedOut links
        $("#pickup_dates_container")
          .find("a.dateLink.grayedOut")
          .attr("tabindex", "-1");
        // remove tab from delivery dates container
        $("#delivery_dates_container")
          .find("a.dateLink, button.ui-datepicker-trigger")
          .attr("tabindex", "-1");
      }
    });
  }

  function createHolidayDeliveryCountdown(getDate, beforeHoliday) {
    var holidayDatesClosed = $.ajax({
      url: "/storefronts/datesClosed?tempThemeDate=" + getDate,
      dataType: "json",
    });

    holidayDatesClosed.done(function (response) {
      // Set up holiday delivery timer
      setUpTimer(
        "delivery",
        true,
        beforeHoliday,
        response.delivery_cutoff_difference
      );
    });
  }

  function handleDeliveryToday(delivery_today, isBeforeHoliday) {
    // Remove active class from today date
    // Add grayed out class to today date
    // Hide the timer
    // Hide the mobile timer
    if (!delivery_today) {
      $("#delivery_datepicker .todayDate").removeClass("active");
      $("#delivery_datepicker .todayDate").addClass("grayedOut");
      $("#delivery_datepicker .todayDate").attr("aria-disabled", "true");
      if (!isBeforeHoliday) {
        $(".orderTimer").hide();

        if (mobileCountdownContainer) {
          mobileCountdownContainer.hide();
        }
      }

      return;
    }
  }

  function handlePickupToday(pickup_today) {
    // Remove active class
    // add grayed out class
    if (!pickup_today) {
      $("#pickup_datepicker .todayDate").removeClass("active");
      $("#pickup_datepicker .todayDate").addClass("grayedOut");
      $("#pickup_datepicker .todayDate").attr("aria-disabled", "true");
      $("#pickupCutoffCopyDesktop").remove();

      return;
    }

    // Set up timer if pickup is today
    $("#pickup_datepicker .todayDate").addClass("active");
    $("#pickup_datepicker .todayDate").removeClass("grayedOut");
    $("#pickup_datepicker .todayDate").attr("aria-disabled", "false");
  }

  function handlePickupDisabled(pickup_disabled) {
    if (pickup_disabled) {
      $("#pickup_dates_container").addClass("hide");
      $("#pickup_dates_container").removeClass("active");
    }

    // Hide pickup dates if the closed dates are more than the four upcoming days
    if (!pickup_disabled) {
      $(
        "#pickupTab, #pickup_dates_container, .product__datepicker-tabs-container"
      ).removeClass("hide");

      // Remove styling from the dates container since no tabs
      $("#delivery_dates_container").addClass(
        "product__datepicker-days-container"
      );

      $(".product__datepicker-free-delivery.no-pickup").removeClass(
        "no-pickup"
      );

      return;
    }
  }

  function setUpTimer(
    type,
    isHolidayTimer,
    beforeHoliday,
    cutoff_difference,
    isAvailableToday
  ) {
    // Date countdown

    // testing code
    // cutoff_difference = {
    //   d: 0,
    //   days: 0,
    //   first_last_day_of: 0,
    //   h: 0,
    //   have_special_relative: 0,
    //   have_weekday_relative: 0,
    //   i: 0,
    //   invert: 1,
    //   m: 0,
    //   s: 10,
    //   special_amount: 0,
    //   special_type: 0,
    //   weekday: 0,
    //   weekday_behavior: 0,
    //   y: 0,
    // };
    // end testing code

    // console.log(type, isHolidayTimer, beforeHoliday, cutoff_difference, isAvailableToday);

    // new Date(year, mth-1, day, hr, min, sec)-date/time to count down to
    days = cutoff_difference.d * 86400000;
    hour = cutoff_difference.h * 3600000;
    minute = cutoff_difference.i * 60000;
    seconds = cutoff_difference.s * 1000;

    timeLeft = days + hour + minute + seconds;

    cutoff_time = new Date(new Date().getTime() + timeLeft);

    // The code that sets up the timer
    // format in lowercase means optional value
    // the layout option {h<}{h>} (hours for example) makes the optional sections disappear when they are zero
    // significant: 2 means show 2 significant values
    var countDownSettings;

    if (type === "delivery") {
      if (isHolidayTimer) {
        // Initialize holiday timer
        countDownSettings = {
          layout:
            '<div class="order-timer--time-days">' +
            "{dnn}" +
            "<span>{dl}</span>" +
            "</div>" +
            '<div class="order-timer--time-separator">{sep}</div>' +
            '<div class="order-timer--time-hours">' +
            "{hnn}" +
            "<span>{hl}</span>" +
            "</div>" +
            '<div class="order-timer--time-separator">{sep}</div>' +
            '<div class="order-timer--time-minutes">' +
            "{mnn}" +
            "<span>{ml}</span>" +
            "</div>" +
            '<div class="order-timer--time-separator">{sep}</div>' +
            '<div class="order-timer--time-seconds">' +
            "{snn}" +
            "<span>{sl}</span>" +
            "</div>",
          until: cutoff_time,
          onExpiry: holidayCountdownExpired,
        };

        $(".order-timer__container .defaultCountdown").countdown(
          countDownSettings
        );

        // Push timer init and timer value to GTM dataLayer
        dataLayer.push({
          event: "lv.lovinglyExclusive.timer.initialized",
          ly: {
            lovinglyExclusive: {
              timer: {
                initialized: {
                  timeout_hours: parseFloat(
                    (Math.round(
                      (days + hour + minute + seconds) / 1000 / 60 / 60
                    ) *
                      100) /
                      100
                  ).toFixed(2),
                },
              },
            },
          },
        });

        // Hide order timer container
        $(".order-timer__container").removeClass("hide");

        // If mobile timer exists (product page) hide that too
        if ($(".product__countdown-mobile")) {
          $(".product__countdown-mobile").removeClass("hide");
        }
      } else if (isAvailableToday) {
        // Always initialize tiny timer for datepicking
        countDownSettings = {
          layout: "<div>{hnn}{hl}{sep}{mnn}{ml}{sep}{snn}{sl}<div>",
          until: cutoff_time,
          onExpiry: function () {
            countdownExpired("delivery", false);
          },
        };

        // Add a hidden timer for delivery cutoff
        $("body").append(
          '<div id="deliveryCountdown" style="position:absolute;top:-9999px;left:-9999px;"></div>'
        );

        // Assign the countdown to this new element
        $("#deliveryCountdown").countdown(countDownSettings);

        if (!beforeHoliday) {
          // If no holiday timer, make one for today
          countDownSettings = {
            layout:
              '<div class="order-timer--time-days">' +
              "{dnn}" +
              "<span>{dl}</span>" +
              "</div>" +
              '<div class="order-timer--time-separator">{sep}</div>' +
              '<div class="order-timer--time-hours">' +
              "{hnn}" +
              "<span>{hl}</span>" +
              "</div>" +
              '<div class="order-timer--time-separator">{sep}</div>' +
              '<div class="order-timer--time-minutes">' +
              "{mnn}" +
              "<span>{ml}</span>" +
              "</div>" +
              '<div class="order-timer--time-separator">{sep}</div>' +
              '<div class="order-timer--time-seconds">' +
              "{snn}" +
              "<span>{sl}</span>" +
              "</div>",
            until: cutoff_time,
            onExpiry: function () {
              countdownExpired("delivery", true);
            },
          };

          $(".order-timer__container .defaultCountdown").countdown(
            countDownSettings
          );

          // Hide order timer container
          $(".order-timer__container").removeClass("hide");

          // If mobile timer exists (product page) hide that too
          if ($(".product__countdown-mobile")) {
            $(".product__countdown-mobile").removeClass("hide");
          }
        }
      }
    }

    if (type === "pickup" && isAvailableToday) {
      countDownSettings = {
        layout: "<div>{hnn}{hl}{sep}{mnn}{ml}{sep}{snn}{sl}<div>",
        until: cutoff_time,
        onExpiry: function () {
          countdownExpired("pickup");
        },
      };

      // Add a hidden timer for pickup cutoff
      $("body").append(
        '<div id="pickupCountdown" style="position:absolute;top:-9999px;left:-9999px;"></div>'
      );

      // Assign the countdown to this new element
      $("#pickupCountdown").countdown(countDownSettings);
    }

    $.countdown.setDefaults(
      $("#isUserLocalFrench").val()
        ? $.countdown.regional.fr
        : $.countdown.regional[""]
    );
  }

  function holidayCountdownExpired() {
    $(".orderTimer").hide();

    if (mobileCountdownContainer) {
      mobileCountdownContainer.hide();
    }
  }

  function countdownExpired(type, hideOnExpiry) {
    var datesClosed = $.ajax({
      url: "/storefronts/datesClosed",
      dataType: "json",
    });

    datesClosed.done(function (response) {
      // console.log('countdownExpired', type);

      // This function fires off when the countdown timer hits 0
      // Update the delivery today field.  It's not necessary, but why not keep things organized
      if (type === "delivery") {
        datesResponse.delivery_today = 0;

        // Hide the countdown timer
        if (hideOnExpiry) {
          $(".orderTimer").hide();

          if (mobileCountdownContainer) {
            mobileCountdownContainer.hide();
          }
        }
      } else {
        $(".product__datepicker-pickup-cutoff").remove();

        datesResponse.pickup_today = 0;
      }

      // Disable the today button
      if ($("#" + type + "_datepicker .todayDate").hasClass("active")) {
        // Make today inactive
        $("#hidden_" + type + "_date").val("");
        $("#" + type + "_datepicker .todayDate").removeClass("active");
        $("#" + type + "_datepicker .todayDate").addClass("grayedOut");
        $("#" + type + "_datepicker .todayDate").attr("aria-disabled", "true");
        datesResponse = response;

        chooseClosestAvailableDate(type, datesResponse);
        
        // If this is pickup, make sure the tab is visible
        if (type === "pickup" && !datesResponse.pickup_disabled) {
          $("#pickupTab").removeClass("hide");
          $(".product__datepicker-tabs-container").removeClass("hide");
        }
      }
    });
  }

  init();
})(jQuery, chooseClosestAvailableDate, openProductDatepicker, {
  isBefore,
});
